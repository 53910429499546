<template>
	<!-- 掉量、超成本、流量位 -->
	<div class="ticketCreate">
		<div class="index-top">
			<div class="index-title">
				<div class="title">掉量超成本</div>
			</div>
			<div class="index-prompt">
				<!-- <div class="text">您当前垫款剩余额度为<span style="color:#f0?0">￥{{CompanyList.payment_price - CompanyList.use_price}}</span></div> -->
			</div>
		</div>
		<div class="gongdan">
			<div class="dan">
				<div class="text"><span>*</span>工单标题:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.order_title" placeholder="请填写工单标题"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>广告主名称:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.advertiser_name" placeholder="请提供广告主名称"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>所属行业:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.industry_involved" placeholder="请提供广告主所属行业"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>问题发生时间:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.problem_time" placeholder="请提供问题发生的具体时间（段）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>账户ID:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="formList.account_id" placeholder="请输入对应广告主ID（adv_id）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>计划ID:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="formList.project_id" placeholder="请输入对应计划ID（ad_id）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>客户ID:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="formList.customer_id" placeholder="请输入正确的客户ID"></el-input></div>
			</div>
			<div class="dan quan">
				<div class="text"><span>*</span>问题描述:（请具体描述问题情况、问题背景、具体诉求）</div>
				<div class="inputbox">
					<Tinymce1 ref="Tinymce1" @getTreeData='onproblem_description' uniqueId='uniqueId1'></Tinymce1>
					<!-- <el-input type="textarea" :rows="6" v-model="formList.problem_description" placeholder="请具体描述问题情况、问题背景、具体诉求"></el-input> -->
				</div>
			</div>
			<div class="dan">
				<div class="text">投放平台:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.platform" placeholder=""></el-input></div>
			</div>
			<div class="dan quan">
				<div class="text"><span>*</span>一站式问问对外版排查截图:</div>
				<div class="inputbox">
						<!-- :action="$api.file_upload + '?token=7ce563cc4f49544991de6ed0ac743bbe'" -->
					<el-upload
						class="avatar-uploader"
						list-type="picture-card"
						action="https://user.erlangcha.com/api/file/upload?token=7ce563cc4f49544991de6ed0ac743bbe"
						:on-success="onAskhandleAvatarSuccess"
						:limit='10'
						:before-upload="onAskbeforeAvatarUpload"
						:on-remove="onAskhandleRemove"
						>
						<!-- <img v-if="formList.hug_pak_img_url" :src="formList.hug_pak_img_url" class="avatar"> -->
						<i class="el-icon-plus avatar-uploader-icon"></i>
						<div class="el-upload__tip" slot="tip">请提供一站式智能问答或者AD计划诊断截图文件不大于 20MB，最多可传 10 个，扩展名为：.jpg，.jpeg，.png，.doc，.docx，.pdf，.csv，.xls，.xlsx，.ppt，.zip，.rar，.mp3，.m4a，.mp4</div>
					</el-upload>
				</div>
			</div>
			<div class="dan quan">
				<div class="text">附件:</div>
				<div class="inputbox">
					<el-upload
						class="avatar-uploader"
						action="https://user.erlangcha.com/api/file/upload?token=7ce563cc4f49544991de6ed0ac743bbe"
						list-type="picture-card"
						:on-success="onAcchandleAvatarSuccess"
						:limit='20'
						:before-upload="onAskbeforeAvatarUpload"
						:on-remove="onAcchandleRemove"
						>
						<!-- <img v-if="formList.acc_img_url" :src="formList.acc_img_url" class="avatar"> -->
						<i class="el-icon-plus avatar-uploader-icon"></i>
						<div class="el-upload__tip" slot="tip">支持图片、视频、压缩包等文件不大于 20MB，最多可传 20 个，扩展名为：.jpg，.jpeg，.png，.doc，.docx，.pdf，.csv，.xls，.xlsx，.ppt，.zip，.rar，.mp3，.m4a，.mp4</div>
					</el-upload>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>问题类型:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.type_problem" placeholder="请选择对应的问题类型"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>问题发生日期:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.date_occurrence" placeholder="请提供问题发生的具体时间（段）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>影响范围:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.sphere_influence" placeholder="请明确问题的影响范围"></el-input></div>
			</div>
			<div class="dan quan">
				<div class="text"><span>*</span>问题涉及的账户ID:</div>
				<div class="inputbox">
					<el-input type="textarea" :rows="6" v-model="formList.problem_acc_id" placeholder="请提供对应截图，并附上对应链接"></el-input>
				</div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>影响端:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.affect_the_end" placeholder="请选择发生问题的流量位"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>【计划概况】计划起投时间:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.plan_starting_time" placeholder="请提供问题发生的具体时间（段）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>【计划概况】当前投放状态:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.plan_ment_status" placeholder="请选择计划目前的状态"></el-input></div>
			</div>
			<div class="dan">
				<div class="text"><span>*</span>【计划概况】是否通过冷启动:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.plan_through_cold" placeholder="计划是否已通过冷启动（满足20个转比）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text">【计划概况】通过冷启动时间:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.plan_through_time" placeholder="请填写广告账户ID（限1条）"></el-input></div>
			</div>
			<div class="dan">
				<div class="text">【计划概况】对比账户:</div>
				<div class="inputbox"><el-input type="text" v-model="formList.plan_compare_acc" placeholder="请提供正常投放的对比账户ID（如有）"></el-input></div>
			</div>
			<div class="dan quan">
				<div class="text"><span>*</span>【AD-查询】异常时间点数据截图:（请提供对应截图，并附上对应链接）</div>
				<div class="inputbox">
					<Tinymce2 ref="Tinymce2" @getTreeData='onad_abnormal_point' uniqueId='uniqueId2'></Tinymce2>
				</div>
			</div>
			<div class="dan quan">
				<div class="text"><span>*</span>【AD-查询】问题时间点前后操作日志截图:（请提供对应截图并附上对应链接）</div>
				<div class="inputbox">
					<Tinymce3 ref="Tinymce3" @getTreeData='onad_snapshots' uniqueId='uniqueId3'></Tinymce3>
					<!-- <el-input type="textarea" :rows="6" v-model="formList.ad_snapshots" placeholder="请提供对应截图并附上对应链接"></el-input> -->
				</div>
			</div>
			<div class="dan">
				<div class="text">店铺ID:</div>
				<div class="inputbox"><el-input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="formList.store_id" placeholder=""></el-input></div>
			</div>
			<div class="dan quan submit">
				<el-button @click="onCancel" :disabled="delenteAnniu">取消</el-button>
				<el-button type="primary" @click="onSubmit" :disabled="delenteAnniu">确认提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
// import { component } from 'vue/types/umd'
import Tinymce1 from './tinymce.vue'
import Tinymce2 from './tinymce.vue'
import Tinymce3 from './tinymce.vue'
export default {
	data () {
		return {
			formList:{
				order_title:'',//工单标题
				advertiser_name:'',//广告主名称
				industry_involved:'',//所属行业
				problem_time:'',//问题发生时间
				account_id:'',//账户ID
				project_id:'',//计划ID
				customer_id:'',//客户ID
				problem_description:'',//问题描述
				platform:'',//投放平台**
				hug_pak_img_url:[],//巨量问问对版排查截图
				acc_img_url:[],//附件**
				type_problem:'',//问题类型
				date_occurrence:'',//问题发生日期
				sphere_influence:'',//影响范围
				problem_acc_id:'',//问题涉及的账户ID
				affect_the_end:'',//影响端
				plan_starting_time:'',//【计划概况】计划起投时间
				plan_ment_status:'',//【计划概况】当前投放状态
				plan_through_cold:'',//【计划概况】是否通过冷启动
				plan_through_time:'',//【计划概况】通过冷启动时间**
				plan_compare_acc:'',//【计划概况】对比账户**
				ad_abnormal_point:'',//【AD-查询】异常时间点数据截图
				ad_snapshots:'',//【AD-查询】问题时间点前后操作日志截图
				store_id:'',//店铺ID
			},
			delenteAnniu:false,//按钮禁用
		}
	},
	components:{
		Tinymce1,
		Tinymce2,
		Tinymce3,
	},
	mounted(){
	},

	methods: {
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },

		//巨量问问对外版排查截图
		onAskhandleAvatarSuccess(res, file,fileList) {
			this.formList.hug_pak_img_url = this.onImgUrlList(fileList)
		},
		// 巨量图片删除
		onAskhandleRemove(file,fileList){
			this.formList.hug_pak_img_url = this.onImgUrlList(fileList)
		},
		//附件上传
		onAcchandleAvatarSuccess(res,file,fileList) {
			this.formList.acc_img_url = this.onImgUrlList(fileList)
			// this.formList.acc_img_url = URL.createObjectURL(file.raw);
		},
		//附件删除
		onAcchandleRemove(file,fileList){
			this.formList.acc_img_url = this.onImgUrlList(fileList)
			console.log(this.formList.acc_img_url)
		},
		//图片列表循环获取id
		onImgUrlList(List){
			var ImgUrlList = []
			List.forEach((v,k) => {
				ImgUrlList.push(v.response.data.file_url)
			});
			return ImgUrlList
		},
		
		//图片上传不得大于20M
		onAskbeforeAvatarUpload(file) {
			const isLt20M = file.size / 1024 / 1024 < 20;
			if (!isLt20M) {
				this.$message.error('上传头像图片大小不能超过 20MB!');
			}
			return isLt20M;
		},
		//问题描述取子组件传来的值
		onproblem_description(data){
			this.formList.problem_description = data
		},
		//【AD-查询】异常时间点数据截图取子组件传来的值
		onad_abnormal_point(data){
			this.formList.ad_abnormal_point = data
		},
		//【AD-查询】问题时间点前后操作日志截图取子组件传来的值
		onad_snapshots(data){
			this.formList.ad_snapshots = data
		},
		//提交
		onSubmit(){
			if(this.formList.order_title == ''){this.$message({message: '工单标题不能为空', type: 'warning'}); return}
			if(this.formList.advertiser_name == ''){this.$message({message: '广告主名称不能为空', type: 'warning'}); return}
			if(this.formList.industry_involved == ''){this.$message({message: '所属行业不能为空', type: 'warning'}); return}
			if(this.formList.problem_time == ''){this.$message({message: '问题发生时间不能为空', type: 'warning'}); return}
			if(this.formList.account_id == ''){this.$message({message: '账户ID不能为空', type: 'warning'}); return}
			if(this.formList.project_id == ''){this.$message({message: '计划ID不能为空', type: 'warning'}); return}
			if(this.formList.customer_id == ''){this.$message({message: '客户ID不能为空', type: 'warning'}); return}
			if(this.formList.problem_description == ''){this.$message({message: '问题描述不能为空', type: 'warning'}); return}
			if(this.formList.hug_pak_img_url == []){this.$message({message: '一站式问问对外版排查截图不能为空', type: 'warning'}); return}
			if(this.formList.type_problem == ''){this.$message({message: '问题类型不能为空', type: 'warning'}); return}
			if(this.formList.date_occurrence == ''){this.$message({message: '问题发生日期不能为空', type: 'warning'}); return}
			if(this.formList.sphere_influence == ''){this.$message({message: '影响范围不能为空', type: 'warning'}); return}
			if(this.formList.problem_acc_id == ''){this.$message({message: '问题涉及的账户ID不能为空', type: 'warning'}); return}
			if(this.formList.affect_the_end == ''){this.$message({message: '影响端不能为空', type: 'warning'}); return}
			if(this.formList.plan_starting_time == ''){this.$message({message: '【计划概况】计划起投时间不能为空', type: 'warning'}); return}
			if(this.formList.plan_ment_status == ''){this.$message({message: '【计划概况】当前投放状态不能为空', type: 'warning'}); return}
			if(this.formList.plan_through_cold == ''){this.$message({message: '【计划概况】是否通过冷启动不能为空', type: 'warning'}); return}
			if(this.formList.ad_abnormal_point == ''){this.$message({message: '【AD-查询】异常时间点数据截图不能为空', type: 'warning'}); return}
			if(this.formList.ad_snapshots == ''){this.$message({message: '【AD-查询】问题时间点前后操作日志截图不能为空', type: 'warning'}); return}
			console.log(this.formList)
			this.$service.post(this.$api.accept_order,this.formList, (res)=> {
				if(res.code == '200'){
					this.$message({message: '提交成功',type: 'success'})
					setTimeout(() => {
						this.$router.go(0)
					}, 1000);
				}
			})
		},
		onCancel(){
			this.formList.order_title = '',//工单标题
			this.formList.advertiser_name = '',//广告主名称
			this.formList.industry_involved = '',//所属行业
			this.formList.problem_time = '',//问题发生时间
			this.formList.account_id = '',//账户ID
			this.formList.project_id = '',//计划ID
			this.formList.customer_id = '',//客户ID
			this.formList.problem_description = '',//问题描述
			this.formList.platform = '',//投放平台**
			this.formList.hug_pak_img_url = [],//巨量问问对版排查截图
			this.formList.acc_img_url = [],//附件**
			this.formList.type_problem = '',//问题类型
			this.formList.date_occurrence = '',//问题发生日期
			this.formList.sphere_influence = '',//影响范围
			this.formList.problem_acc_id = '',//问题涉及的账户ID
			this.formList.affect_the_end = '',//影响端
			this.formList.plan_starting_time = '',//【计划概况】计划起投时间
			this.formList.plan_ment_status = '',//【计划概况】当前投放状态
			this.formList.plan_through_cold = '',//【计划概况】是否通过冷启动
			this.formList.plan_through_time = '',//【计划概况】通过冷启动时间**
			this.formList.plan_compare_acc = '',//【计划概况】对比账户**
			this.formList.ad_abnormal_point = '',//【AD-查询】异常时间点数据截图
			this.formList.ad_snapshots = '',//【AD-查询】问题时间点前后操作日志截图
			this.formList.store_id = ''//店铺ID
		}
	}
}
</script>

<style lang="scss" scoped>
.ticketCreate{
	.gongdan{
		// display: block;
		.dan{
			margin-right: 40px;
			display: block;
			.text{
				text-align: left;
				margin: 10px 0;
			}
			.inputbox{
				margin-left: 0;
			}
			&.quan{
				width:1640px;
			}
			&.submit{
				margin-top: 20px;
				text-align: center;
			}
		}
	}
}
</style>
<style lang="scss">
.ticketCreate{
	.gongdan{
		// display: block;
		.dan{
			.inputbox{
				.el-upload{
					width: 148px;
					height: 148px;
				}
				.el-upload__tip{
					color: #888;
				}
			}
		}
	}
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 148px;
		height: 148px;
		line-height: 148px;
		text-align: center;
	}
	.avatar {
		width: 148px;
		height: 148px;
		display: block;
	}
}
</style>
